export const RESPONSE_ERRORS = "RESPONSE_ERRORS";
export const RESPONSE_SUCCESS = "RESPONSE_SUCCESS";

export const SOURCE = "vshp";
export const ORGANIZATION = "college";

export const FILE_MAX_SIZE = "31457280";

export const DATE_FORMAT = "DD.MM.YYYY";
export const DATE_AND_TIME_FORMAT = "DD.MM.YYYY | HH:mm";
export const TIME_FORMAT = "HH:mm";

export const CHAT_OPEN_CLASS = "chat-open";
export const CHAT_MENU_OPEN_CLASS = "chat-pre-open";
export const CHAT_STICKERS_PREFIX = "[sticker:";

export const ACCESSIBILITY = "ACCESSIBILITY";
export const ACCESSIBILITY_ACTIVE_SIZE = "ACCESSIBILITY_ACTIVE_SIZE";
export const ACCESSIBILITY_ACTIVE_COLOR = "ACCESSIBILITY_ACTIVE_COLOR";

export const ACCESSIBILITY_CONTENT = ["A", "A", "A"];
export const ACCESSIBILITY_SIZE_CLASS = "accessibility-size";
export const ACCESSIBILITY_COLOR_CLASS = "accessibility-color";
export const ACCESSIBILITY_OPEN_CLASS = "accessibility-open";

/* Message */
export const MESSAGE_DEFAULT_DURATION = 5;
export const MESSAGE_MOBILE_TOP_OFFSET = 20;
export const MESSAGE_TABLET_TOP_OFFSET = 30;
export const MESSAGE_DESKTOP_TOP_OFFSET = 120;

export const MAX_INDICATORS_STD = 2;
export const MAX_INDICATORS_COUNT = 5;
