import { combineReducers } from "redux";
import { PROGRAMS, PROGRAMS_ARE_LOADING, PROGRAMS_DEGREES, PROGRAMS_DEGREES_LOADING, PROGRAM_LOADED, PROGRAM } from "../constants/programs";
import { HYDRATE } from "next-redux-wrapper";

export function programs(state = [], action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.programs.programs;
    case PROGRAMS:
      return action.data;
    default:
      return state;
  }
}

export function programsAreLoading(state = true, action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.programs.programsAreLoading;
    case PROGRAMS_ARE_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function programsDegrees(state = [], action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.programs.programsDegrees;
    case PROGRAMS_DEGREES:
      return action.data;
    default:
      return state;
  }
}

export function programsDegreesAreLoading(state = true, action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.programs.programsDegreesAreLoading;
    case PROGRAMS_DEGREES_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function program(state = [], action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.programs.program;
    case PROGRAM:
      return action.data;
    default:
      return state;
  }
}

export function programIsLoaded(state = true, action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.programs.programIsLoaded;
    case PROGRAM_LOADED:
      return action.bool;
    default:
      return state;
  }
}

export const programsReducers = () =>
  combineReducers({
    programs: programs,
    programsAreLoading: programsAreLoading,
    programsDegrees: programsDegrees,
    programsDegreesAreLoading: programsDegreesAreLoading,
    program: program,
    programIsLoaded: programIsLoaded,
  });
