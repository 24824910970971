let API_URL       = "https://my.vshp.online/api/v1/";
let REACT_APP_ENV = process.env.NODE_ENV || "development";
let API_WS_ROOT   = "wss://my.vshp.online/cable";
let DOMAIN        = ".vshp.online";
let URL           = "https://vshp.online";
let URL_PROFILE   = "https://my.vshp.online";

if (REACT_APP_ENV === "staging") {
  API_URL         = "https://my-vshp-online.vshp.tech/api/v1/";
  API_WS_ROOT     = "wss://my-vshp-online.vshp.tech/cable";
  DOMAIN          = "my-vshp-online.vshp.tech";
  URL             = "https://vshp-online.vshp.tech";
  URL_PROFILE     = "https://my-vshp-online.vshp.tech";
} else if (REACT_APP_ENV === "development") {
  API_URL         = "https://my-vshp-online.vshp.tech/api/v1/";
  API_WS_ROOT     = "wss://my-vshp-online.vshp.tech/cable";
  DOMAIN          = "localhost";
  URL             = "https://vshp-online.vshp.tech";
  URL_PROFILE     = "https://my-vshp-online.vshp.tech";
}

console.log("App environment is set to " + REACT_APP_ENV);

export { API_URL, REACT_APP_ENV, API_WS_ROOT, DOMAIN, URL, URL_PROFILE };
