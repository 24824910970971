import youtubePicture1 from "../../public/images/youtube_preview/youtube_picture_1.jpg";
import youtubePicture2 from "../../public/images/youtube_preview/youtube_picture_2.jpg";
import youtubePicture3 from "../../public/images/youtube_preview/youtube_picture_3.jpg";
import youtubePicture4 from "../../public/images/youtube_preview/youtube_picture_4.jpg";
import youtubePicture5 from "../../public/images/youtube_preview/youtube_picture_5.jpg";
import youtubePicture6 from "../../public/images/youtube_preview/youtube_picture_6.jpg";

export const VIDEOS = "VIDEOS";

export const VIDEOS_INFO = [
  {
    url: "https://youtu.be/uaB5pTFJJ6g",
    preview: youtubePicture1,
    title: "Как зарегистрироваться на сайте «Высшая школа предпринимательства»?",
  },
  {
    url: "https://youtu.be/f-V7MghMsL8",
    preview: youtubePicture2,
    title: "Как начать обучение на сайте «Высшая школа предпринимательства»?",
  },
  {
    url: "https://youtu.be/IkmZRHaxvXg",
    preview: youtubePicture3,
    title: "Как оплатить обучение на сайте «Высшая школа предпринимательства»?",
  },
];

export const VIDEOS_ABOUT = [
  {
    url: "https://youtu.be/tjK1CcjP-44",
    preview: youtubePicture4,
  },
  {
    url: "https://youtu.be/CB6qecWeLGc",
    preview: youtubePicture5,
  },
  {
    url: "https://youtu.be/k6LXT8njkEQ",
    preview: youtubePicture6,
  },
];
